import React from 'react';
import { NavLink } from 'react-router-dom';

const Fortress = window.cccisd.fortress;
const ResourceNotAvailable = ({ intended }) => {
    return (
        <div>
            <h2>Resource not available</h2>
            {Fortress.auth() ? (
                <p>
                    Your role does not have access to this resource. It is intended for {intended}.
                </p>
            ) : (
                <p>
                    You must be <NavLink to="/welcome">logged in</NavLink> to view this resource.
                </p>
            )}
        </div>
    );
};

export default ResourceNotAvailable;
