import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';

const hostName = window.location.hostname;
const Footer = props => {
    const { className, isWordpress } = props;

    return (
        <footer className={`${style.footer} ${style.laravel} ${style.laravelFooter}`}>
            <div className={`${className} ${style.content}`}>
                <address>
                    <h6 className={isWordpress ? style.wordpress : style.laravel}>
                        {isWordpress
                            ? 'Family-based Behavioral Treatment'
                            : 'Family-based Approach for Healthy Lifestyles (RYSE)'}
                    </h6>
                    <ul>
                        <li>
                            <b>Mailing Address:</b> Mail stop 8134-29-2100 | 660 S Euclid | St. Louis, MO 63110-1010
                        </li>
                        <li>
                            <b>Office Location:</b> 4590 Children’s Place, 2nd Floor, suite 2100 | St. Louis, MO 63110
                        </li>
                        <li>
                            <b>Fax:</b> 314-788-2086
                        </li>
                    </ul>
                    <p>©{new Date().getFullYear()} Washington University in St. Louis</p>
                </address>
                <div className="row">
                    <div className="col col-md-4" />
                    <div className={`col col-md-8 ${style.ccc}`}>
                        Website and software developed by{' '}
                        <a
                            className={style.noWrap}
                            href={`//www.3cisd.com?r=${hostName}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            3C Institute
                        </a>
                        .{' '}
                        <span className={style.noWrap}>
                            |{' '}
                            <a
                                href={`//www.3cisd.com/terms-of-use/?r=${hostName}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Terms of Use
                            </a>
                        </span>{' '}
                        <span className={style.noWrap}>
                            |{' '}
                            <a
                                href={`//www.3cisd.com/privacy-policy/?r=${hostName}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Privacy Policy
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
    isWordpress: PropTypes.bool,
};

Footer.defaultProps = {
    className: 'container',
    isWordpress: false,
};

export default Footer;
