import React, { useState, useEffect } from 'react';
import style from './style.css';
import { ResponsiveLine } from 'cccisd-nivo/line';
import PropTypes from 'prop-types';

const ChartPlayer = props => {
    // Responsive chart does not render in Report unless
    // you allow time for the report container size itself first
    const [isRenderChart, setIsRenderChart] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsRenderChart(true);
        }, 300);
    }, []);

    const {
        type,
        settings: { heading },
        widgetProps,
    } = props;

    const sessionWeeksMapper = {};
    for (let i = 1; i <= 19; i++) {
        sessionWeeksMapper[i] = parseFloat(widgetProps.settings[`session${i}Weeks`]);
    }

    let chartData = [
        {
            id: type,
            color: '#015F87',
            data: [],
        },
    ];
    Object.keys(widgetProps.data).forEach(devTag => {
        if (devTag.startsWith(type)) {
            const sessionNumber = parseFloat(devTag.substring(devTag.lastIndexOf('_') + 1));
            const weekNumber = sessionWeeksMapper[sessionNumber];
            let weight = parseFloat(widgetProps.data[devTag]);
            chartData[0].data.push({
                x: weekNumber,
                y: Number.isNaN(weight) ? null : weight,
            });
        }
    });

    // filter null's to make all dots connect
    chartData[0].data = chartData[0].data.filter(d => d.y);

    function getYScale() {
        const dataPoints = chartData[0].data;
        if (dataPoints.length === 0) {
            return {
                type: 'linear',
                min: 0,
                max: 500,
                stacked: true,
                reverse: false,
            };
        }

        let minWeight = dataPoints[0].y || 0;
        let maxWeight = dataPoints[0].y || 300;
        dataPoints.forEach(dataPoint => {
            if (dataPoint.y && dataPoint.y < minWeight) {
                minWeight = dataPoint.y;
            }
            if (dataPoint.y && dataPoint.y > maxWeight) {
                maxWeight = dataPoint.y;
            }
        });
        // make roughly 20 y-axis grid lines
        let diff = maxWeight - minWeight;
        let scale = Math.ceil(diff / 14); // 3 above max and 3 below mins

        return {
            type: 'linear',
            min: minWeight - scale * 3,
            max: maxWeight + scale * 3,
            stacked: true,
            reverse: false,
        };
    }

    let chartProps = {
        data: chartData,
        margin: { top: 30, right: 20, bottom: 60, left: 60 },
        xScale: {
            type: 'linear',
            min: 1,
            max: sessionWeeksMapper[19],
        },
        yScale: getYScale(),
        pointSize: 12,
        lineWidth: 3,
        colors: { datum: 'color' },
        enableGridX: true,
        gridXValues: Array.from({ length: sessionWeeksMapper[19] }, (_, i) => i + 1),
        enableGridY: true,
        animate: true,
        axisTop: null,
        axisRight: null,
        axisBottom: {
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: Array.from({ length: sessionWeeksMapper[19] }, (_, i) => i + 1),
            legend: widgetProps.settings.xAxisLabel,
            legendOffset: 50,
            legendPosition: 'middle',
        },
        axisLeft: {
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: widgetProps.settings.yAxisLabel,
            legendOffset: -50,
            legendPosition: 'middle',
        },
        legends: [],
        tooltip: ({
            point: {
                data: { x, y },
            },
        }) => (
            <div className={style.tooltip}>
                <p>Week: {x}</p>
                <p>Weight: {y} lbs</p>
            </div>
        ),
    };

    return (
        <div className={style.chartWrapper}>
            <h3 className={style.heading}>{heading}</h3>
            <div style={{ width: '770px', height: '340px' }}>
                {isRenderChart && <ResponsiveLine {...chartProps} />}
            </div>
        </div>
    );
};

ChartPlayer.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgetProps: PropTypes.object,
    type: PropTypes.string.isRequired,
};

// Filters are passed from reportTemplatePlayer HOC to the Report Player and Widgets
ChartPlayer.defaultProps = {
    filters: {},
};

export default ChartPlayer;
