import React from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import widgets from '../widgets';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import style from './style.css';

const mockData = {
    parent_weight_1: '200',
    parent_weight_2: '210',
    parent_weight_3: '199',
    parent_weight_4: '205',
    parent_weight_5: '188',
    parent_weight_6: '199',
    parent_weight_7: '201',
    parent_weight_8: '200',
    parent_weight_9: '200',
    parent_weight_10: '194',
    parent_weight_11: '184',
    parent_weight_12: '190',
    parent_weight_13: '200',
    parent_weight_14: '197',
    parent_weight_15: '200',
    parent_weight_16: '201',
    parent_weight_17: '203',
    parent_weight_18: '190',
    parent_weight_19: '183',
    child_weight_1: '99',
    child_weight_2: '100',
    child_weight_3: '98',
    child_weight_4: '97',
    child_weight_5: '103',
    child_weight_6: '110',
    child_weight_7: '80',
    child_weight_8: '120',
    child_weight_9: '100',
    child_weight_10: '101',
    child_weight_11: '105',
    child_weight_12: '115',
    child_weight_13: '118',
    child_weight_14: '121',
    child_weight_15: '123',
    child_weight_16: '121',
    child_weight_17: '126',
    child_weight_18: '129',
    child_weight_19: '126',
};

const Component = props => {
    const { header, childChart, parentChart, footer } = props.widgets;

    const now = new Date();
    return (
        <Report
            downloadFilename={`RYSE Progress Report_${now.getMonth() +
                1}-${now.getDate()}-${now.getFullYear()}`}
        >
            <Page>
                <div className={style.pageWrapper}>
                    <div>
                        {header}
                        {parentChart}
                        {childChart}
                    </div>
                    {footer}
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
};

export default reportTemplatePlayer({
    getWidgets: props => {
        return widgets.map(w => ({
            ...w,
            widgetProps: { ...props, data: props.isPreview ? mockData : props.data },
        }));
    },
    filterFields: [],
})(Component);
