import React, { useState, useEffect } from 'react';
import style from './style.css';
import Table from 'cccisd-table';
import copy from 'copy-to-clipboard';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import _get from 'lodash/get';
import notification from 'cccisd-notification';
import Modal from 'cccisd-modal';
import axios from 'cccisd-axios';

import query from './parent_manage_child.graphql';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

const ParentManage = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [child, setChild] = useState(null);
    const [resetUrl, setResetUrl] = useState('');
    const [error, setError] = useState('');
    let noRecordsMessage = 'A child has not yet been created for you.';

    function checkNotNull(childResponse, pathList) {
        return pathList.every(p => {
            return _get(childResponse, p, null) || null;
        });
    }

    function copyText(event) {
        event.preventDefault();
        if (child) {
            let isActivate = !child.hasLoggedIn;
            copy(isActivate ? child.activationUrl : resetUrl);
            notification({
                message: 'Link copied successfully!',
                type: 'success',
                duration: 5000,
            });
        }
    }

    function renderModalBody() {
        if (error) {
            return (
                <div>
                    <p>{error}</p>
                    <p>
                        Please contact 3C Institute for support at{' '}
                        <a href="mailto:support@3cisd.com">support@3cisd.com</a> or by phone at{' '}
                        <a href="tel:+19843160406" className="text-nowrap">
                            (984) 316-0406
                        </a>
                        .
                    </p>
                </div>
            );
        }

        let isActivate = child && !child.hasLoggedIn;
        return (
            <div className={style.showOverflow}>
                <h3>{isActivate ? 'Activation Link' : 'Reset Link'}:</h3>
                <p>
                    Visit the below link to {isActivate ? 'set' : 'reset'} the password for <b>{child.username}</b>
                </p>
                <p>
                    <a href={isActivate ? child.activationUrl : resetUrl}>
                        {isActivate ? child.activationUrl : resetUrl}
                    </a>
                </p>
                <button type="button" className={`btn btn-warning ${style.copyButton}`} onClick={copyText}>
                    Copy URL
                </button>
            </div>
        );
    }

    useEffect(() => {
        async function fetchChild() {
            const response = await client.query({
                query,
                variables: {
                    parentPawn: Fortress.user.acting.id,
                },
                fetchPolicy: 'network-only',
            });
            const data = response.data.roles.child;
            const childResponse = checkNotNull(data, [
                'pawn.pawnId',
                'pawn.pawnHash',
                'user.username',
                'user.activationUrl',
            ])
                ? {
                      pawnHash: data.pawn.pawnHash,
                      pawnId: data.pawn.pawnId,
                      username: data.user.username,
                      activationUrl: data.user.activationUrl,
                      lastLoginDate: data.user.lastLoginDate || '-',
                      hasLoggedIn: data.user.hasLoggedIn,
                  }
                : null;

            // need to fetch reset url if they've logged in once already
            if (childResponse && childResponse.hasLoggedIn) {
                const responseReset = await axios.post(Boilerplate.route('api.app.passwordResetLink'), {
                    pawnId: childResponse.pawnId,
                    pawnHash: childResponse.pawnHash,
                });
                if (responseReset.data && responseReset.data.status === 'success') {
                    const url = _get(responseReset, 'data.data.resetUrl', null);
                    setResetUrl(url);
                } else {
                    setError('Could not generate password reset link.');
                }
            }

            setChild(childResponse);
            setIsLoading(false);
        }
        fetchChild();
    }, []);

    const settings = {
        name: 'parent_manage_child',
        rowKey: 'pawnId',
        noRecordsMessage,
        columns: [
            {
                name: 'username',
                label: "Child's Username",
            },
            {
                name: 'lastLoginDate',
                label: 'Last Login',
            },
        ],
        showRowActions: false,
        showPerPageOptions: false,
        hideShowingRowsSummary: true,
        data: child ? [child] : [],
    };

    return (
        <Loader loading={isLoading} removeChildren>
            <div className={style.pageWrapper}>
                <Table {...settings} />
                {child && (
                    <div className={style.buttonWrapper}>
                        <Modal
                            trigger={
                                <button type="button" className="btn btn-primary">
                                    Reset Child&#39;s Password
                                </button>
                            }
                            title="Reset Child's Password"
                        >
                            {renderModalBody()}
                        </Modal>
                    </div>
                )}
            </div>
        </Loader>
    );
};

export default ParentManage;
