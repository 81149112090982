import React from 'react';
import style from './style.css';

const Status = ({ value }) => {
    let className;
    switch (value) {
        case 'Complete':
            className = style.complete;
            break;
        case 'In Progress':
            className = style.inProgress;
            break;
        default:
            className = style.notStarted;
    }

    return <span className={`${className} ${style.pad}`}>{value || 'Not Started'}</span>;
};

export default Status;
