import React, { useState, useEffect } from 'react';
import style from './style.css';
import Modal from 'cccisd-modal';
import DashboardButton from '../../components/DashboardButton';
import ChartIcon from 'cccisd-icons/stats-dots';
import Loader from 'cccisd-loader';
import { client } from 'cccisd-apollo';
import queryWeights from './queryWeights.graphql';
import { DEPLOYMENT_HANDLE } from '../DataEntry';
import _get from 'lodash/get';
import notification from 'cccisd-notification';
import Player from '../../vendor/reports/reportTemplates/WeightReport/Player';
import widgets from '../../vendor/reports/reportTemplates/WeightReport/widgets';
const Appdefs = window.cccisd && window.cccisd.appDefs;

const ModalContent = props => {
    const { pawnId } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [weightData, setWeightData] = useState(null);

    useEffect(() => {
        async function fetchWeights() {
            const response = await client.query({
                query: queryWeights,
                variables: {
                    pawnId,
                    deploymentHandle: DEPLOYMENT_HANDLE,
                },
                fetchPolicy: 'network-only',
            });
            const data = _get(response, 'data.flows.assignmentProgress.devTags', {}) || {};
            setWeightData(data);
            setIsLoading(false);
        }
        fetchWeights();
    }, []);

    if (isLoading) {
        return (
            <div className={style.loadingWrapper}>
                <Loader loading type="inline" />
            </div>
        );
    }

    const handle = 'weightReport';
    const settings =
        Appdefs &&
        Appdefs.reports &&
        Appdefs.reports.list &&
        Appdefs.reports.list.find(report => report.id === handle);

    if (!settings) {
        notification({
            message: `Cannot find a report where field "id" is equal to "${handle}"`,
            type: 'danger',
        });
        return null;
    }

    return (
        <div className={style.reportWrapper}>
            <Player
                settings={settings.reportTemplateSettings}
                filters={[]}
                isPreview={false}
                widgets={widgets}
                data={weightData}
                {...props}
            />
        </div>
    );
};

const WeightCharts = settings => {
    return ({ row }) => {
        const parentPawnId = row['pawn.pawnId'];
        const studyId = row['fields.studyId'];

        return (
            <Modal
                trigger={<DashboardButton text="View Charts" icon={<ChartIcon />} />}
                title={`Weight Charts for Study ID: ${studyId}`}
                size="large"
            >
                <ModalContent pawnId={parentPawnId} studyId={studyId} />
            </Modal>
        );
    };
};

export default WeightCharts;
