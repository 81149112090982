import React from 'react';
import style from './style.css';
import PropTypes from 'prop-types';
import headerImage from '../../../../../../../../images/graphic.png';
import { Html } from 'cccisd-wysiwyg';

const HeaderPlayer = props => {
    let {
        isPreview,
        settings: { title, subtitle, idTitle },
        widgetProps: { studyId },
    } = props;
    if (isPreview) {
        studyId = '1112223333';
    }

    return (
        <header className={style.header}>
            <img src={headerImage} className={style.headerImage} alt="logo" />
            <div className={style.headerText}>
                <Html content={title} />
                <Html content={subtitle} />
                <Html content={idTitle.replace(/\$\{studyId\}/g, studyId)} />
            </div>
        </header>
    );
};

HeaderPlayer.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgetProps: PropTypes.object,
};

HeaderPlayer.defaultProps = {
    filters: {},
};

export default HeaderPlayer;
