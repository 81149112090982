import React from 'react';
import style from './style.css';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

const FooterPlayer = props => {
    let {
        settings: { leftText },
    } = props;

    return (
        <footer className={style.footer}>
            <div>
                <span>{leftText}</span>
            </div>
            <div>
                <span className={style.date}>{format(new Date(), 'MMMM yyyy')}</span>
                <span className={style.pageNumber}>1</span>
            </div>
        </footer>
    );
};

FooterPlayer.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgetProps: PropTypes.object,
};

FooterPlayer.defaultProps = {
    filters: {},
};

export default FooterPlayer;
