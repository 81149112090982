import React from 'react';
import Modal from 'cccisd-modal';
import DashboardButton from '../../components/DashboardButton';
import PencilIcon from 'cccisd-icons/pencil7';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';

export const DEPLOYMENT_HANDLE = 'dataEntry';

const DataEntry = settings => {
    return ({ value, row }) => {
        const parentPawnId = row['pawn.pawnId'];
        const parentPawnHash = row['pawn.pawnHash'];
        const studyId = row['fields.studyId'];

        return (
            <Modal
                trigger={<DashboardButton text="Enter Data" icon={<PencilIcon />} />}
                title={`Data Entry for Study ID: ${studyId}`}
                size="medium"
            >
                <DeploymentPlayer
                    deploymentHandle={DEPLOYMENT_HANDLE}
                    pawnId={parentPawnId}
                    pawnHash={parentPawnHash}
                    disableLayout
                />
            </Modal>
        );
    };
};

export default DataEntry;
