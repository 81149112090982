import React from 'react';
import { Field, CccisdInput } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    heading: 'Heading',
};

const ChartBuilder = props => {
    return (
        <>
            <Field name="heading" component={CccisdInput} label="Heading" />
        </>
    );
};

// widgetBuilder is a Formik Wrapper.
export default widgetBuilder({ initialValues })(ChartBuilder);
