import React, { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import axios from 'cccisd-axios';
import KeyIcon from 'cccisd-icons/key5';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import notification from 'cccisd-notification';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;

// need to have separate functional component to use useState
const Component = ({ row }) => {
    const pawnId = row['pawn.pawnId'];
    const pawnHash = row['pawn.pawnHash'];
    const username = row['user.username'];
    const hasLoggedIn = row['user.hasLoggedIn'];
    const activationUrl = row['user.activationUrl'];
    const [resetUrl, setResetUrl] = useState('');
    const [error, setError] = useState('');
    const [isLoadingResetUrl, setIsLoadingResetUrl] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        async function fetchResetUrl() {
            setIsLoadingResetUrl(true);
            const response = await axios.post(Boilerplate.route('api.app.passwordResetLink'), {
                pawnId,
                pawnHash,
            });
            if (response.status === 200 && response?.data?.status === 'success' && response?.data?.data?.resetUrl) {
                setResetUrl(response?.data?.data?.resetUrl);
            } else {
                setError('Could not generate password reset link.');
            }
            setIsLoadingResetUrl(false);
        }
        if (isModalOpen && hasLoggedIn) {
            fetchResetUrl();
        }
    }, [isModalOpen]);

    function copyText(event) {
        event.preventDefault();
        const url = hasLoggedIn ? resetUrl : activationUrl;
        copy(url);
        notification({
            message: 'Link copied successfully!',
            type: 'success',
            duration: 5000,
        });
    }

    function renderModalBody() {
        if (error) {
            return (
                <div>
                    <p>{error}</p>
                    <p>
                        Please contact 3C Institute for support at{' '}
                        <a href="mailto:support@3cisd.com">support@3cisd.com</a> or by phone at{' '}
                        <a href="tel:+19843160406" className="text-nowrap">
                            (984) 316-0406
                        </a>
                        .
                    </p>
                </div>
            );
        }

        return (
            <div>
                <p>
                    Copy the {hasLoggedIn ? 'link' : 'activation link'} below and send to <b>{username}</b> to{' '}
                    {hasLoggedIn ? 'reset' : 'set'} their password:
                </p>
                {isLoadingResetUrl ? (
                    <h1>
                        <Loader type="inline" loading />
                    </h1>
                ) : (
                    <>
                        <div className={style.link}>
                            <a href={hasLoggedIn ? resetUrl : activationUrl} className={style.link}>
                                {hasLoggedIn ? resetUrl : activationUrl}
                            </a>
                        </div>
                        <button type="button" className="btn btn-warning btn-sm" onClick={copyText}>
                            Copy URL
                        </button>
                    </>
                )}
            </div>
        );
    }

    return (
        <Modal
            title={hasLoggedIn ? 'Reset Password' : 'Activate Account'}
            trigger={
                <button type="button" className={`btn btn-xs ${hasLoggedIn ? 'btn-warning' : 'btn-success'}`}>
                    <KeyIcon />
                    &nbsp;&nbsp;{hasLoggedIn ? 'Reset' : 'Activate'}
                </button>
            }
            beforeShow={() => setIsModalOpen(true)}
            afterClose={() => setIsModalOpen(false)}
        >
            {renderModalBody()}
        </Modal>
    );
};

const PasswordReset = settings => {
    return props => <Component {...props} />;
};

export default PasswordReset;
