import React from 'react';
import { Field, CccisdInput } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    leftText: 'Washington University School of Medicine',
};

const FooterBuilder = props => {
    return (
        <>
            <Field name="leftText" component={CccisdInput} label="Left Text" />
        </>
    );
};

// widgetBuilder is a Formik Wrapper.
export default widgetBuilder({ initialValues })(FooterBuilder);
