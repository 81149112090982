import React from 'react';
import Header from '../../../../components/Header';
import style from './style.css';

/*
 This component is used for the Quest header if "showHeader" is set to true
*/
export default () => {
    return (
        <div className={style.assignmentHeader}>
            <Header allowSwitchRoles={false} noLogoLink />
        </div>
    );
};
