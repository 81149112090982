import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';
import { Link } from 'react-router-dom';
import _find from 'lodash/find';
import style from './style.css';

// add real images here
import welcome1 from '../../../images/welcome/welcome1.png';
import welcome2 from '../../../images/welcome/welcome2.png';
import welcome3 from '../../../images/welcome/welcome3.png';
import graphic from '../../../images/graphic.png';

var Fortress = window.cccisd.fortress;
var AppDefs = window.cccisd.appDefs;
const instructionalMessage = 'Use your username and password to access your portal!';

const Welcome = () => {
    let homepageRoute = _find(AppDefs.routes, r => r.handle === AppDefs.navs[0].homepage);

    return (
        <div className={'row ' + style.wrapper}>
            <div className="col-md-7">
                <div className={style.imageContainer}>
                    <div className={style.imageDiv} style={{ backgroundImage: `url(${welcome1})` }} />
                    <div className={style.imageDiv} style={{ backgroundImage: `url(${welcome2})` }} />
                    <div className={style.imageDiv} style={{ backgroundImage: `url(${welcome3})` }} />
                </div>
                <div className={style.headingContainer}>
                    <img src={graphic} alt="logo" />
                    <div className={style.text}>
                        <h2>Welcome to Family-based Approach for Healthy Lifestyles (RYSE)</h2>
                        <p className={style.smScreen}>{instructionalMessage}</p>
                    </div>
                    <p className={style.xsScreen}>{instructionalMessage}</p>
                </div>
            </div>
            <div className="col-md-1">
                <div className={style.divider}>
                    <div />
                </div>
            </div>
            <div className="col-md-4">
                {Fortress.auth() ? (
                    <div className={style.welcomeBox}>
                        <p>Hello, {Fortress.user.username}</p>
                        {homepageRoute && (
                            <Link to={homepageRoute.url}>
                                <button type="button" className="btn btn-primary">
                                    Go To Home
                                </button>
                            </Link>
                        )}
                    </div>
                ) : (
                    <LoginForm showPasswordToggle />
                )}
            </div>
        </div>
    );
};

export default Welcome;
