import React from 'react';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import { Field, CccisdInput } from 'cccisd-formik';
import widgets from '../widgets';

export const initialValues = {
    session1Weeks: '1',
    session2Weeks: '2',
    session3Weeks: '3',
    session4Weeks: '4',
    session5Weeks: '5',
    session6Weeks: '6',
    session7Weeks: '7',
    session8Weeks: '8',
    session9Weeks: '9',
    session10Weeks: '10',
    session11Weeks: '11',
    session12Weeks: '12',
    session13Weeks: '13',
    session14Weeks: '14',
    session15Weeks: '15',
    session16Weeks: '16',
    session17Weeks: '17',
    session18Weeks: '18',
    session19Weeks: '19',
    yAxisLabel: 'Weight (lbs)',
    xAxisLabel: 'Session Number',
    hideFilterBar: true,
    filterFields: [],
    otherFilterOptions: [],
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

const WeightReportBuilder = () => {
    return (
        <>
            <BuilderBlock title="Chart Axes">
                <Field name="xAxisLabel" component={CccisdInput} label="X Axis Label" />
                <Field name="yAxisLabel" component={CccisdInput} label="Y Axis Label" />
            </BuilderBlock>
            <BuilderBlock title="Session Weeks">
                {Array.from({ length: 19 }, (_, i) => i + 1).map(session => (
                    <Field
                        key={session}
                        name={`session${session}Weeks`}
                        component={CccisdInput}
                        type="number"
                        label={`Session ${session} Week Number`}
                    />
                ))}
            </BuilderBlock>
        </>
    );
};

export default reportTemplateBuilder({ widgets })(WeightReportBuilder);
