import React from 'react';
import style from './style.css';
import PropTypes from 'prop-types';

const DashboardButton = ({ text, icon }) => {
    return (
        <button type="button" className={'btn btn-primary ' + style.button}>
            {icon}
            &nbsp;&nbsp;{text}
        </button>
    );
};

DashboardButton.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.node,
};

export default DashboardButton;
